function formatPriceInRupiah(price, withDecimal = false) {
    const number = parseFloat(price);

    if (isNaN(number)) {
        return 0;
    }

    const options = {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    };

    if (withDecimal) {
        options.minimumFractionDigits = 2;
        options.maximumFractionDigits = 2;
    }

    const formatter = new Intl.NumberFormat('id-ID', options);

    const formattedPrice = formatter.format(number);
    return formattedPrice;
}
window.formatPriceInRupiah = formatPriceInRupiah;